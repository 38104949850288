import { uploadFiles } from '../core';
import { useDebugValue } from 'react';
import { UploadOptions } from '../core/upload';
import { useUpload, UseUploadResponse } from './useUpload';

export interface UseUploadFilesResponse extends UseUploadResponse {
  /**
   * @param files ReactNative files array, a DOM FileList or an array of files
   */
  upload: (files: any) => Promise<string[]>;

  urls: string[];
}

/**
 * @param type {string} image type (e.g. `profile-pic`) check clevershop control for a complete list
 * @param options
 */
export const useUploadFiles = (
  type: string,
  options?: UploadOptions,
): UseUploadFilesResponse => {
  useDebugValue(type);
  return useUpload(type, uploadFiles, options);
};
