import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { changeColorToClassName, Colors } from '../../utils/color';

export default function TextButton({
  color,
  children,
  disabled,
  className,
  onClick,
  notUnderline,
}) {
  const injectingClassName = (Array.isArray(className)) ? className : [className];
  return (
    <div
      role="button"
      tabIndex={0}
      className={[
        ...injectingClassName,
        changeColorToClassName(color),
        (notUnderline ? 'sendbird-textbutton--not-underline' : 'sendbird-textbutton'),
        (disabled ? 'sendbird-textbutton--disabled' : ''),
      ].join(' ')}
      onClick={onClick}
      onKeyPress={onClick}
    >
      {children}
    </div>
  );
}

TextButton.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onClick: PropTypes.func,
  notUnderline: PropTypes.bool,
  disabled: PropTypes.bool,
};

TextButton.defaultProps = {
  color: Colors.ONBACKGROUND_1,
  className: '',
  onClick: () => { },
  notUnderline: false,
  disabled: false,
};
