import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export default function AutoRefresh({
  repeatFunc,
}) {
  const [label, setLabel] = useState(repeatFunc());

  useEffect(() => {
    const interval = setInterval(() => {
      if (label !== repeatFunc()) {
        setLabel(repeatFunc());
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (<div className="sendbird-repeat-text">{label}</div>);
}

AutoRefresh.propTypes = {
  repeatFunc: PropTypes.func.isRequired,
};
