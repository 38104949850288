export default {
  ADD: 'ADD',
  ATTACH: 'ATTACH',
  ARROW_LEFT: 'ARROW_LEFT',
  AVATAR_DARK: 'AVATAR_DARK',
  AVATAR_LIGHT: 'AVATAR_LIGHT',
  BROADCAST_LARGE_DARK: 'BROADCAST_LARGE_DARK',
  BROADCAST_LARGE_LIGHT: 'BROADCAST_LARGE_LIGHT',
  BROADCAST_DARK: 'BROADCAST_DARK',
  BROADCAST_LIGHT: 'BROADCAST_LIGHT',
  BROADCAST: 'BROADCAST',
  FROZEN_DARK: 'FROZEN_DARK',
  FROZEN_LIGHT: 'FROZEN_LIGHT',
  CAMERA: 'CAMERA',
  MORE: 'MORE',
  MUTE: 'MUTE',
  NO_THUMBNAIL: 'NO_THUMBNAIL',
  CHECKBOX: 'CHECKBOX',
  CHECKBOX_OFF: 'CHECKBOX_OFF',
  CLOSE: 'CLOSE',
  COPY: 'COPY',
  CHAT: 'CHAT',
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  DISCONNECTED: 'DISCONNECTED',
  DUMMY: 'DUMMY',
  EDIT: 'EDIT',
  ERROR: 'ERROR',
  ERROR_FILLED: 'ERROR_FILLED',
  EMOJI_FAILED: 'EMOJI_FAILED',
  EMOJI_REACTIONS_ADD: 'EMOJI_REACTIONS_ADD',
  EMOJI_REACTIONS_ADD_GRAY: 'EMOJI_REACTIONS_ADD_GRAY',
  DELIVERED: 'DELIVERED',
  DOCUMENT: 'DOCUMENT',
  DOWNLOAD: 'DOWNLOAD',
  FILE_AUDIO: 'FILE_AUDIO',
  FILE_DOCUMENT: 'FILE_DOCUMENT',
  GIF: 'GIF',
  INFO: 'INFO',
  LEAVE: 'LEAVE',
  MEMBERS: 'MEMBERS',
  NOTIFICATIONS: 'NOTIFICATIONS',
  PHOTO: 'PHOTO',
  PLAY: 'PLAY',
  PLUS: 'PLUS',
  READ: 'READ',
  REFRESH: 'REFRESH',
  SEND: 'SEND',
  SENT: 'SENT',
  SUPER_GROUP: 'SUPER_GROUP',
  SHEVRON: 'SHEVRON',
  SHEVRON_DOWN: 'SHEVRON_DOWN',
  SPINNER: 'SPINNER',
  SPINNER_LARGE: 'SPINNER_LARGE',
  USER: 'USER',
  ICON_OPERATOR: 'ICON_OPERATOR',
  ICON_BANNED: 'ICON_BANNED',
  ICON_FREEZE: 'ICON_FREEZE',
  ICON_MUTED: 'ICON_MUTED',
  ICON_MUTED_FG: 'ICON_MUTED_FG',
  TOGGLE_ON: 'TOGGLE_ON',
  TOGGLE_OFF: 'TOGGLE_OFF',
};
