import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Label, { LabelTypography, LabelColors } from '../Label';
import './index.scss';

export const InputLabel = ({ children }) => (
  <Label
    type={LabelTypography.CAPTION_3}
    color={LabelColors.ONBACKGROUND_1}
    className="sendbird-input-label"
  >
    {children}
  </Label>
);

InputLabel.propTypes = {
  children: PropTypes.string.isRequired,
};

// future: add validations? onChange? more props etc etc
const Input = React.forwardRef((props, ref) => {
  const {
    value,
    placeHolder,
    disabled,
    name,
    required,
  } = props;
  const [inputValue, setInputValue] = useState(value);
  return (
    <div className="sendbird-input">
      <input
        required={required}
        ref={ref}
        name={name}
        disabled={disabled}
        value={inputValue}
        className="sendbird-input--input"
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
      />
      {(placeHolder && !inputValue) && (
        <Label
          className="sendbird-input--placeholder"
          type={LabelTypography.BODY_1}
          color={LabelColors.ONBACKGROUND_3}
        >
          {placeHolder}
        </Label>
      )}
    </div>
  );
});

Input.propTypes = {
  value: PropTypes.string,
  placeHolder: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

Input.defaultProps = {
  value: '',
  placeHolder: '',
  disabled: false,
  required: false,
};

export default Input;
