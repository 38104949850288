import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

import Label from '../Label';

const CLASS_NAME = 'sendbird-tooltip';

export default function Tooltip({
  className,
  children,
}) {
  const injectingClassName = Array.isArray(className) ? className : [className];
  injectingClassName.unshift(CLASS_NAME);

  return (
    <div className={injectingClassName.join(' ')}>
      <Label
        className={`${CLASS_NAME}__text`}
      >
        {children}
      </Label>
    </div>
  );
}

Tooltip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
};

Tooltip.defaultProps = {
  className: '',
  children: '',
};
