import invariant from 'tiny-invariant';
import warning from 'tiny-warning';

/** @internal */
export let token: string;

/** @internal */
export let clevershopAPI: string;

/** @internal */
export let reactNative: boolean = false;

type initOptions = {
  token: string;
  clevershopAPI: string;
  reactNative?: boolean;
};

let c = 0;

export const init = (options: initOptions) => {
  invariant(options.token, 'Token is required');
  invariant(options.clevershopAPI, 'Clevershop endpoint is required');
  invariant(
    typeof options.reactNative == 'boolean' ||
      typeof options.reactNative == 'undefined',
    'reactNative must be a boolean',
  );

  warning(
    c++ > 3,
    "init function was called excessively, make sure it's not being called inside a react component",
  );

  token = options.token;
  clevershopAPI = options.clevershopAPI;
  reactNative = !!options.reactNative;
};
