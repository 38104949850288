import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import Icon, { IconTypes } from '../Icon';

export default function Loader({
  children,
  className,
  width,
  height,
}) {
  const injectingClassName = (Array.isArray(className)) ? className : [className];
  return (
    <div
      className={[...injectingClassName, 'sendbird-loader'].join(' ')}
      style={{
        width: typeof width === 'string' ? width : `${width}px`,
        height: typeof height === 'string' ? height : `${height}px`,
      }}
    >
      {children}
    </div>
  );
}

Loader.propTypes = {
  children: PropTypes.element,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

Loader.defaultProps = {
  children: <Icon
    type={IconTypes.SPINNER}
    width="26px"
    height="26px"
  />,
  className: '',
  width: '26px',
  height: '26px',
};
