export default {
  initialized: false,
  loading: false,
  allMessages: [],
  currentGroupChannel: { members: [] },
  hasMore: false,
  lastMessageTimeStamp: 0,
  emojiContainer: {},
  readStatus: {},
  unreadCount: 0,
  unreadSince: null,
  isInvalid: false,
};
