import { SendbirdTypes } from "../../types";

export const DEFAULT_URL_PREFIX = 'https://static.sendbird.com/sample/cover/cover_';

export const getChannelAvatarSource = (channel: SendbirdTypes["GroupChannel"], currentUserId: string): string | string[] => {
  if (channel && channel.coverUrl) {
    if (!(new RegExp(`^${DEFAULT_URL_PREFIX}`).test(channel.coverUrl))) {
      return channel.coverUrl;
    }
  }

  return channel && channel.members
    ? channel.members
      .filter((member) => member.userId !== currentUserId)
      .map(({ profileUrl }) => profileUrl)
    : [];
};


export const useDefaultAvatar = (channel: SendbirdTypes["GroupChannel"]): boolean => {
  if (channel && channel.coverUrl) {
    if (new RegExp(`^${DEFAULT_URL_PREFIX}`).test(channel.coverUrl)) {
      return true;
    }
    return false;
  }
  return true;
}
