import React, { useContext } from 'react';

import { LocalizationContext } from '../../lib/LocalizationContext';
import Label, {
  LabelTypography,
  LabelColors,
} from '../Label';
import Icon, { IconTypes, IconColors } from '../Icon';

import './index.scss';

function ConnectionStatus() {
  const { stringSet } = useContext(LocalizationContext);
  return (
    <div
      className="sendbird-conversation__connection-status"
    >
      <Label type={LabelTypography.BODY_2} color={LabelColors.ONBACKGROUND_2}>
        { stringSet.TRYING_TO_CONNECT }
      </Label>
      <Icon
        type={IconTypes.DISCONNECTED}
        fillColor={IconColors.SENT}
        height="14px"
        width="14px"
      />
    </div>
  );
}

export default ConnectionStatus;
