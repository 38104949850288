import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const CLASS_NAME = 'sendbird-iconbutton';

const IconButton = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    disabled = false,
    type,
    height,
    width,
    onClick,
    onBlur,
  } = props;

  const [pressed, setPressed] = useState('');

  const injectingClassName = Array.isArray(className) ? className : [className];
  injectingClassName.unshift(CLASS_NAME);

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={`${injectingClassName.join(' ')} ${pressed}`}
      disabled={disabled}
      ref={ref}
      type={type}
      style={{ height, width }}
      onClick={(e) => {
        if (disabled) { return; }
        setPressed('sendbird-iconbutton--pressed');
        onClick(e);
      }}
      onBlur={(e) => {
        setPressed('');
        onBlur(e);
      }}
    >
      <span className="sendbird-iconbutton__inner">
        {children}
      </span>
    </button>
  );
});

IconButton.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.any,
  ]).isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
};

IconButton.defaultProps = {
  height: '56px',
  width: '56px',
  className: '',
  type: 'button',
  disabled: false,
  onClick: () => { },
  onBlur: () => { },
};

export default IconButton;
