import Subject from './Subject';
import { useEffect, useState } from 'react';

/** @internal */
export const createUseProgress = (subject: Subject<number>) => () => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    subject.subscribe({
      next: value => {
        setProgress(value);
      },
    });
    return () => {
      subject.unsubscribe();
    };
  }, []);
  return progress;
};
