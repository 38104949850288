import { reactNative, token } from './init';

/** @internal  */
export const createFormData = () => {
  if (FormData) {
    return new FormData();
  } else {
    const FormData = require('form-data');
    return new FormData();
  }
};

const uploadImagesQuery =
  'mutation Upload($files: [Upload!]!, $type: String!, $token: ID!) { uploadImages(files: $files, type: $type, token: $token) { urls } }';

/**
 * @internal
 * not the cleanest approach but it's the approach with least bytes rather than duplicate the above string
 */
export const uploadFilesQuery = uploadImagesQuery.replace(
  'uploadImages',
  'uploadFiles',
);

/**
 * @internal
 * @param file
 */
const formatFile = (file: any) => {
  if (reactNative) {
    const uri = file.uri || file.path;
    const pathArr = uri.split('/');

    return { uri, type: file.mime, name: pathArr[pathArr.length - 1] };
  }
  return file;
};

/**
 * @internal
 * @description constructs the formData for the uploadFiles and uploadImages
 */
export const buildUploadFilesFormData = (
  filesArray: any[],
  type: string,
  query = uploadImagesQuery,
) => {
  const formData = createFormData();

  // as per the graphql multipart request spec
  const variables = { files: [...filesArray].fill(null), type, token };
  const map: Record<number, string[]> = {};

  const images = filesArray.map((file: any, index: number) => {
    map[index] = [`variables.files.${index}`];
    return formatFile(file);
  });

  const operations = { query, variables };
  formData.append('operations', JSON.stringify(operations));
  formData.append('map', JSON.stringify(map));
  images.forEach((file: any, i: number) => formData.append(i, file));

  return formData;
};
