import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import Label, { LabelColors, LabelTypography } from '../Label';

export default function AdminMessage({
  className,
  message,
}) {
  if (!message.messageType || message.messageType !== 'admin') { // change to use message.isAdminMessage()
    return null;
  }

  const injectingClassName = (Array.isArray(className)) ? className : [className];
  return (
    <div className={[...injectingClassName, 'sendbird-admin-message'].join(' ')}>
      <Label className="sendbird-admin-message__text" type={LabelTypography.CAPTION_2} color={LabelColors.ONBACKGROUND_2}>
        {message.message}
      </Label>
    </div>
  );
}

AdminMessage.propTypes = {
  message: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
  ])),
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

AdminMessage.defaultProps = {
  message: {},
  className: '',
};
