import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
  getMessageCreatedAt,
  getIsSentFromStatus,
} from './util';
import { UserProfileContext } from '../../lib/UserProfileContext';
import Avatar from '../Avatar/index';
import UserProfile from '../UserProfile';
import Label, { LabelTypography, LabelColors } from '../Label';
import ContextMenu, { MenuItem, MenuItems } from '../ContextMenu';
import IconButton from '../IconButton';
import Icon, { IconTypes, IconColors } from '../Icon';
import MessageStatus from '../MessageStatus';
import EmojiReactions from '../EmojiReactions';
import {
  isImage,
  isVideo,
  unSupported,
} from '../FileViewer/types';
import {
  getSenderName,
  getSenderProfileUrl,
} from '../../utils/utils';
import useMouseHover from '../../hooks/onMouseHover';

const noop = () => { };

const OUTGOING_THUMBNAIL_MESSAGE = 'sendbird-outgoing-thumbnail-message';
const INCOMING_THUMBNAIL_MESSAGE = 'sendbird-incoming-thumbnail-message';
const GROUPING_PADDING = '1px';
const NORMAL_PADDING = '8px';

export default function ThumbnailMessage({
  message = {},
  userId,
  disabled,
  isByMe,
  onClick,
  showRemove,
  status,
  resendMessage,
  useReaction,
  emojiAllMap,
  membersMap,
  toggleReaction,
  memoizedEmojiListItems,
  chainTop,
  chainBottom,
}) {
  return (
    isByMe
      ? (
        <OutgoingThumbnailMessage
          userId={userId}
          status={status}
          message={message}
          onClick={onClick}
          disabled={disabled}
          chainTop={chainTop}
          showRemove={showRemove}
          membersMap={membersMap}
          chainBottom={chainBottom}
          useReaction={useReaction}
          emojiAllMap={emojiAllMap}
          resendMessage={resendMessage}
          toggleReaction={toggleReaction}
          memoizedEmojiListItems={memoizedEmojiListItems}
        />
      )
      : (
        <IncomingThumbnailMessage
          userId={userId}
          status={status}
          message={message}
          onClick={onClick}
          chainTop={chainTop}
          membersMap={membersMap}
          chainBottom={chainBottom}
          useReaction={useReaction}
          emojiAllMap={emojiAllMap}
          toggleReaction={toggleReaction}
          memoizedEmojiListItems={memoizedEmojiListItems}
        />
      )
  );
}

export function OutgoingThumbnailMessage({
  message = {},
  userId,
  disabled,
  onClick,
  showRemove,
  status,
  resendMessage,
  useReaction,
  emojiAllMap,
  membersMap,
  toggleReaction,
  memoizedEmojiListItems,
  chainTop,
  chainBottom,
}) {
  const {
    type,
    url,
    localUrl,
  } = message;
  const messageRef = useRef(null);
  const parentContainRef = useRef(null);
  const menuRef = useRef(null);
  const reactionAddRef = useRef(null);
  const [mousehover, setMousehover] = useState(false);
  const [moreActive, setMoreActive] = useState(false);

  const showReactionAddButton = (useReaction && emojiAllMap && emojiAllMap.size > 0);
  const MemoizedEmojiListItems = memoizedEmojiListItems;
  const isMessageSent = getIsSentFromStatus(status);

  const handleMoreIconClick = () => {
    setMoreActive(true);
  };
  const handleMoreIconBlur = () => {
    setMoreActive(false);
  };

  useMouseHover({
    ref: messageRef,
    setHover: setMousehover,
  });

  return (
    <div
      className={OUTGOING_THUMBNAIL_MESSAGE}
      ref={messageRef}
      style={{
        paddingTop: chainTop ? GROUPING_PADDING : NORMAL_PADDING,
        paddingBottom: chainBottom ? GROUPING_PADDING : NORMAL_PADDING,
      }}
    >
      <div className={`${OUTGOING_THUMBNAIL_MESSAGE}--inner`}>
        <div className={`${OUTGOING_THUMBNAIL_MESSAGE}__left-padding`}>
          <div
            className={`${OUTGOING_THUMBNAIL_MESSAGE}-left-padding__more`}
            ref={parentContainRef}
          >
            <ContextMenu
              menuTrigger={(toggleDropdown) => (
                <IconButton
                  ref={menuRef}
                  width="32px"
                  height="32px"
                  onClick={() => {
                    toggleDropdown();
                    handleMoreIconClick();
                  }}
                  onBlur={() => {
                    handleMoreIconBlur();
                  }}
                >
                  <Icon
                    width="24px"
                    height="24px"
                    type={IconTypes.MORE}
                    color={IconColors.CONTENT_INVERSE}
                  />
                </IconButton>
              )}
              menuItems={(closeDropdown) => (
                <MenuItems
                  parentRef={menuRef}
                  parentContainRef={parentContainRef}
                  closeDropdown={closeDropdown}
                  openLeft
                >
                  {
                    (message && message.isResendable && message.isResendable()) && (
                      <MenuItem
                        onClick={() => {
                          resendMessage(message);
                          closeDropdown();
                        }}
                      >
                        Resend
                      </MenuItem>
                    )
                  }
                  <MenuItem onClick={() => {
                    if (disabled) { return; }
                    showRemove(true);
                    closeDropdown();
                  }}
                  >
                    Delete
                  </MenuItem>
                </MenuItems>
              )}
            />
            {
              isMessageSent && showReactionAddButton
              && (
                <ContextMenu
                  menuTrigger={(toggleDropdown) => (
                    <IconButton
                      ref={reactionAddRef}
                      width="32px"
                      height="32px"
                      onClick={() => {
                        toggleDropdown();
                        handleMoreIconClick();
                      }}
                      onBlur={() => {
                        handleMoreIconBlur();
                      }}
                    >
                      <Icon
                        width="24px"
                        height="24px"
                        type={IconTypes.EMOJI_REACTIONS_ADD}
                        color={IconColors.CONTENT_INVERSE}
                      />
                    </IconButton>
                  )}
                  menuItems={(closeDropdown) => (
                    <MemoizedEmojiListItems
                      message={message}
                      parentRef={reactionAddRef}
                      parentContainRef={parentContainRef}
                      closeDropdown={closeDropdown}
                      spaceFromTrigger={{ y: 2 }}
                    />
                  )}
                />
              )
            }
          </div>
          {
            !chainBottom && !(mousehover || moreActive) && (
              <MessageStatus
                message={message}
                status={status}
                className={`${OUTGOING_THUMBNAIL_MESSAGE}-left-padding__status`}
              />
            )
          }
        </div>
        <div className={`${OUTGOING_THUMBNAIL_MESSAGE}__body`}>
          <div
            className={`${OUTGOING_THUMBNAIL_MESSAGE}-body__wrap`}
          >
            <div
              className={`${OUTGOING_THUMBNAIL_MESSAGE}-body__wrap--inner`}
              onClick={isMessageSent ? () => onClick(true) : () => { }}
              onKeyDown={isMessageSent ? () => onClick(true) : () => { }}
              tabIndex={0}
              role="button"
            >
              {
                isVideo(type) && (
                  <>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video
                      className={`${OUTGOING_THUMBNAIL_MESSAGE}-body__video`}
                    >
                      <source src={url || localUrl} type={type} />
                    </video>
                    <Icon
                      className={`${OUTGOING_THUMBNAIL_MESSAGE}-body__video-icon`}
                      width="56px"
                      height="56px"
                      type={IconTypes.PLAY}
                    />
                  </>
                )
              }
              {
                isImage(type) && (
                  <div
                    className={`${OUTGOING_THUMBNAIL_MESSAGE}-body__img`}
                    style={{
                      backgroundImage: `url(${url || localUrl})`,
                      height: '280px',
                      width: '404px',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                  />
                )
              }
              {
                unSupported(type) && (
                  <div className={`${OUTGOING_THUMBNAIL_MESSAGE}-body__other`}>
                    Unknown type
                  </div>
                )
              }
              <div className={`${OUTGOING_THUMBNAIL_MESSAGE}-body__wrap__overlay`} />
            </div>
            {
              (useReaction && message.reactions && message.reactions.length > 0)
              && (
                <EmojiReactions
                  className={`${OUTGOING_THUMBNAIL_MESSAGE}-body__wrap__emoji-reactions`}
                  userId={userId}
                  message={message}
                  emojiAllMap={emojiAllMap}
                  membersMap={membersMap}
                  toggleReaction={toggleReaction}
                  memoizedEmojiListItems={memoizedEmojiListItems}
                />
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export function IncomingThumbnailMessage({
  message = {},
  userId,
  onClick,
  status,
  useReaction,
  emojiAllMap,
  membersMap,
  toggleReaction,
  memoizedEmojiListItems,
  chainTop,
  chainBottom,
}) {
  const {
    type,
    url,
    localUrl,
  } = message;
  const {
    disableUserProfile,
    renderUserProfile,
  } = React.useContext(UserProfileContext);
  const messageRef = useRef(null);
  const parentContainRef = useRef(null);
  const reactionAddRef = useRef(null);
  const avatarRef = useRef(null);
  const [mousehover, setMousehover] = useState(false);
  const [moreActive, setMoreActive] = useState(false);

  const showReactionAddButton = (useReaction && emojiAllMap && emojiAllMap.size > 0);
  const MemoizedEmojiListItems = memoizedEmojiListItems;
  const isMessageSent = getIsSentFromStatus(status);

  const handleMoreIconClick = () => {
    setMoreActive(true);
  };
  const handleMoreIconBlur = () => {
    setMoreActive(false);
  };

  useMouseHover({
    ref: messageRef,
    setHover: setMousehover,
  });

  return (
    <div
      className={INCOMING_THUMBNAIL_MESSAGE}
      ref={messageRef}
      style={{
        paddingTop: chainTop ? GROUPING_PADDING : NORMAL_PADDING,
        paddingBottom: chainBottom ? GROUPING_PADDING : NORMAL_PADDING,
      }}
    >
      {
        !chainTop && (
          <Label
            className={`${INCOMING_THUMBNAIL_MESSAGE}__sender-name`}
            type={LabelTypography.CAPTION_2}
            color={LabelColors.ONBACKGROUND_2}
          >
            {getSenderName(message) || ''}
          </Label>
        )
      }
      <div className={`${INCOMING_THUMBNAIL_MESSAGE}--inner`}>
        <div className={`${INCOMING_THUMBNAIL_MESSAGE}__body`}>
          <div className={`${INCOMING_THUMBNAIL_MESSAGE}-body__wrap`}>
            {
              !chainBottom && (
                <ContextMenu
                  menuTrigger={(toggleDropdown) => (
                    <Avatar
                      onClick={() => {
                        if (!disableUserProfile) {
                          toggleDropdown();
                        }
                      }}
                      className={`${INCOMING_THUMBNAIL_MESSAGE}__avatar`}
                      src={getSenderProfileUrl(message)}
                      width="28px"
                      height="28px"
                    />
                  )}
                  menuItems={(closeDropdown) => (
                    <MenuItems
                      parentRef={avatarRef}
                      // for catching location(x, y) of MenuItems
                      parentContainRef={avatarRef}
                      // for toggling more options(menus & reactions)
                      closeDropdown={closeDropdown}
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      {
                        renderUserProfile
                          ? renderUserProfile({
                            user: message.sender,
                            close: closeDropdown,
                          })
                          : (
                            <UserProfile
                              user={message.sender}
                              onSuccess={closeDropdown}
                            />
                          )
                      }
                    </MenuItems>
                  )}
                />
              )
            }
            <div
              className={`${INCOMING_THUMBNAIL_MESSAGE}-body__wrap--inner`}
              role="button"
              tabIndex={0}
              onClick={isMessageSent ? () => onClick(true) : () => { }}
              onKeyDown={isMessageSent ? () => onClick(true) : () => { }}
            >
              {
                isVideo(type) && (
                  <>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video className={`${INCOMING_THUMBNAIL_MESSAGE}__video`}>
                      <source src={url || localUrl} type={type} />
                    </video>
                    <Icon
                      className={`${INCOMING_THUMBNAIL_MESSAGE}__video-icon`}
                      width="56px"
                      height="56px"
                      type={IconTypes.PLAY}
                    />
                  </>
                )
              }
              {
                isImage(type) && (
                  <div
                    className={`${INCOMING_THUMBNAIL_MESSAGE}__img`}
                    style={{
                      backgroundImage: `url(${url || localUrl})`,
                      height: '280px',
                      width: '404px',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                  />
                )
              }
              {
                unSupported(type) && (
                  <div className={`${INCOMING_THUMBNAIL_MESSAGE}__other`}>
                    Unknown type
                  </div>
                )
              }
              <div className={`${INCOMING_THUMBNAIL_MESSAGE}-body__wrap-overlay`} />
            </div>
            {
              (useReaction && message.reactions && message.reactions.length > 0)
              && (
                <EmojiReactions
                  className={`${INCOMING_THUMBNAIL_MESSAGE}__wrap__emoji-reactions`}
                  userId={userId}
                  message={message}
                  emojiAllMap={emojiAllMap}
                  membersMap={membersMap}
                  toggleReaction={toggleReaction}
                  memoizedEmojiListItems={memoizedEmojiListItems}
                />
              )
            }
          </div>
        </div>
        <div className={`${INCOMING_THUMBNAIL_MESSAGE}__right-padding`}>
          {
            !chainBottom && !(mousehover || moreActive) && (
              <Label
                className={`${INCOMING_THUMBNAIL_MESSAGE}__sent-at`}
                type={LabelTypography.CAPTION_3}
                color={LabelColors.ONBACKGROUND_2}
              >
                {getMessageCreatedAt(message)}
              </Label>
            )
          }
          <div
            className={`${INCOMING_THUMBNAIL_MESSAGE}__more`}
            ref={parentContainRef}
          >
            {
              showReactionAddButton && (
                <ContextMenu
                  menuTrigger={(toggleDropdown) => (
                    <IconButton
                      ref={reactionAddRef}
                      width="32px"
                      height="32px"
                      onClick={() => {
                        toggleDropdown();
                        handleMoreIconClick();
                      }}
                      onBlur={() => {
                        handleMoreIconBlur();
                      }}
                    >
                      <Icon
                        width="24px"
                        height="24px"
                        type={IconTypes.EMOJI_REACTIONS_ADD}
                        color={IconColors.CONTENT_INVERSE}
                      />
                    </IconButton>
                  )}
                  menuItems={(closeDropdown) => (
                    <MemoizedEmojiListItems
                      message={message}
                      parentRef={reactionAddRef}
                      parentContainRef={parentContainRef}
                      closeDropdown={closeDropdown}
                      spaceFromTrigger={{ y: 2 }}
                    />
                  )}
                />
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

ThumbnailMessage.propTypes = {
  message: PropTypes.shape({
    type: PropTypes.string,
    url: PropTypes.string,
    localUrl: PropTypes.string,
  }).isRequired,
  userId: PropTypes.string,
  resendMessage: PropTypes.func,
  status: PropTypes.string,
  isByMe: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  showRemove: PropTypes.func,
  useReaction: PropTypes.bool.isRequired,
  emojiAllMap: PropTypes.instanceOf(Map),
  membersMap: PropTypes.instanceOf(Map),
  toggleReaction: PropTypes.func,
  memoizedEmojiListItems: PropTypes.func,
  chainTop: PropTypes.bool,
  chainBottom: PropTypes.bool,
};
ThumbnailMessage.defaultProps = {
  isByMe: false,
  disabled: false,
  resendMessage: noop,
  onClick: noop,
  showRemove: noop,
  status: '',
  userId: '',
  emojiAllMap: new Map(),
  membersMap: new Map(),
  toggleReaction: noop,
  memoizedEmojiListItems: () => '',
  chainTop: false,
  chainBottom: false,
};

OutgoingThumbnailMessage.propTypes = {
  message: PropTypes.shape({
    type: PropTypes.string,
    url: PropTypes.string,
    localUrl: PropTypes.string,
  }).isRequired,
  userId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  resendMessage: PropTypes.func.isRequired,
  status: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  showRemove: PropTypes.func.isRequired,
  useReaction: PropTypes.bool.isRequired,
  emojiAllMap: PropTypes.instanceOf(Map).isRequired,
  membersMap: PropTypes.instanceOf(Map).isRequired,
  toggleReaction: PropTypes.func.isRequired,
  memoizedEmojiListItems: PropTypes.func.isRequired,
  chainTop: PropTypes.bool.isRequired,
  chainBottom: PropTypes.bool.isRequired,
};
OutgoingThumbnailMessage.defaultProps = {
  status: '',
};

IncomingThumbnailMessage.propTypes = {
  message: PropTypes.shape({
    type: PropTypes.string,
    url: PropTypes.string,
    localUrl: PropTypes.string,
  }).isRequired,
  userId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  status: PropTypes.string,
  useReaction: PropTypes.bool.isRequired,
  emojiAllMap: PropTypes.instanceOf(Map).isRequired,
  membersMap: PropTypes.instanceOf(Map).isRequired,
  toggleReaction: PropTypes.func.isRequired,
  memoizedEmojiListItems: PropTypes.func.isRequired,
  chainTop: PropTypes.bool.isRequired,
  chainBottom: PropTypes.bool.isRequired,
};
IncomingThumbnailMessage.defaultProps = {
  status: '',
};
