import { uploadImage } from '../core';
import { useCallback, useDebugValue, useMemo, useRef, useState } from 'react';
import { UploadOptions } from '../core/upload';
import axios from 'axios';
import { Await, noop } from '../core/utils';
import Subject from './Subject';
import { createUseProgress } from './createUseProgress';

export enum UPLOAD_STATUS {
  IDLE = 'IDLE',
  UPLOADING = 'UPLOADING',
  PROCESSING = 'PROCESSING',
  ERRORED = 'ERRORED',
  CANCELED = 'CANCELED',
}

type Urls = Await<ReturnType<typeof uploadImage>>;

export interface UseUploadResponse {
  /** @description cancel the upload */
  cancel: () => void;

  /** @description an object containing resolution names, and url pairs */
  urls: any;

  /**
   * @description uploads the file, only a single file can be a upload at any given time
   * @param file - either a File or a ReadStream or a ReactNative file
   */
  upload: (file?: any) => Promise<any>;

  /**
   * @description hook returns a number representing the upload progress from 0 to 100
   */
  useProgress: () => number;

  /**
   * @description loading status of the upload, this is true if status is UPLOADING or PROCESSING
   */
  loading: boolean;

  /** @description either the error given when trying upload the image or undefined */
  error: Error | undefined;

  /**
   * @type {UPLOAD_STATUS}
   * @description status describing the the of the upload one of
   *
   *  - IDLE, upload is done or not initiated
   *  - UPLOADING, uploading image to the server
   *  - PROCESSING, upload completed, and the server is processing the image
   *  - ERRORED, failed to upload. check the error field for more info
   *  - CANCELED, user canceled the request
   * @enum {UPLOAD_STATUS}
   */
  status: UPLOAD_STATUS;
}

/**
 * @param type {string} image type (e.g. `profile-pic`) check clevershop control for a complete list
 * @param uploaderFn
 * @param options
 */
export const useUpload = (
  type: string,
  uploaderFn: (file: any, type: string, options: UploadOptions) => any,
  options?: UploadOptions,
): UseUploadResponse => {
  useDebugValue(type);

  const [loading, setLoading] = useState(false);
  const [urls, setUrls] = useState<Urls>();
  const [status, setStatus] = useState<UPLOAD_STATUS>(UPLOAD_STATUS.IDLE);
  const [error, setError] = useState<Error | undefined>();
  const progressSubject = useRef(new Subject<number>());
  const useProgress = useMemo(
    () => createUseProgress(progressSubject.current),
    [progressSubject],
  );

  const cancel = useRef(noop);

  const handleUpload = useCallback(
    async (file?: any) => {
      if (!file || loading) return null;

      setLoading(true);
      progressSubject.current.next(0);
      setStatus(UPLOAD_STATUS.UPLOADING);
      setError(undefined);
      try {
        const urls = await uploaderFn(file, type, {
          onCancelTokenAvailable: token => {
            cancel.current = () => token.cancel('CANCELED');
          },
          onProgress: progress => {
            progressSubject.current.next(Math.round(progress * 100));

            // Upload done waiting for service to finish processing
            if (progress >= 1) setStatus(UPLOAD_STATUS.PROCESSING);
          },
          ...options,
        });

        setUrls(urls);
        setStatus(UPLOAD_STATUS.IDLE);
        return urls;
      } catch (error) {
        if (axios.isCancel(error)) {
          setStatus(UPLOAD_STATUS.CANCELED);
        } else {
          setStatus(UPLOAD_STATUS.ERRORED);
          setError(error);
        }
        return null;
      } finally {
        cancel.current = noop;
        setLoading(false);
      }
    },
    [loading, uploaderFn, type, options],
  );

  return {
    urls,
    loading,
    useProgress,
    status,
    error,
    upload: handleUpload as any,
    cancel: cancel.current,
  };
};
