import axios, { CancelTokenSource } from 'axios';
import invariant from 'tiny-invariant';

import { buildUploadFilesFormData, uploadFilesQuery } from './formData';
import { clevershopAPI, token } from './init';

export type UploadOptions = {
  token?: string;
  clevershopAPI?: string;

  onProgress?: (x: number) => void;
  onCancelTokenAvailable?: (c: CancelTokenSource) => void;
};

// deleting would result in a breaking change
export type UploadImageOptions = UploadOptions;

export type ImageUrls = { raw: string; [url: string]: string };

const handleFiles = (files: any): { files: any[]; isArray: boolean } => {
  // Can't wait till FileList is replace with an array
  if (files?.constructor?.name === 'FileList') {
    return { files: Array.from(files), isArray: true };
  }

  const isArray = Array.isArray(files);
  return { files: isArray ? files : [files], isArray };
};

/** @internal */
async function upload(formData: any, options?: UploadOptions) {
  invariant(
    token ?? options?.token,
    'Upload token is missing. did you call init?',
  );
  invariant(
    clevershopAPI ?? options?.clevershopAPI,
    'Clevershop API missing. did you call init?',
  );

  const source = axios.CancelToken.source();
  options?.onCancelTokenAvailable?.(source);

  const resp = await axios.post(clevershopAPI, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...('getHeaders' in formData && formData.getHeaders()),
    },
    onUploadProgress: ({ loaded, total }) => {
      options?.onProgress?.(loaded / total);
    },
    cancelToken: source.token,
  });

  return await resp.data;
}

export async function uploadImages(
  inputFiles: any[],
  type: string,
  options?: UploadImageOptions,
): Promise<ImageUrls[]> {
  const { files } = handleFiles(inputFiles);
  const formData = buildUploadFilesFormData(files, type);
  const resp = await upload(formData, options);
  return resp.data?.uploadImages?.urls;
}

export async function uploadImage<TFiles extends any>(
  inputFiles: TFiles,
  type: string,
  options?: UploadImageOptions,
): Promise<TFiles extends any[] ? ImageUrls[] : ImageUrls> {
  const { files, isArray } = handleFiles(inputFiles);
  const formData = buildUploadFilesFormData(files, type);
  const resp = await upload(formData, options);
  const urls = resp.data?.uploadImages?.urls;
  return isArray ? urls : urls[0];
}

export async function uploadFiles(
  inputFiles: any,
  type: string,
  options?: UploadImageOptions,
): Promise<ImageUrls[]> {
  const { files } = handleFiles(inputFiles);
  const formData = buildUploadFilesFormData(files, type, uploadFilesQuery);
  const resp = await upload(formData, options);
  return resp.data?.uploadFiles?.urls;
}
