import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import * as utils from './utils';

import { LocalizationContext } from '../../lib/LocalizationContext';
import Label, { LabelTypography, LabelColors } from '../Label';
import Icon, { IconTypes, IconColors } from '../Icon';
import IconButton from '../IconButton';
import AutoRefresh from '../AutoRefresh';
import ChannelAvatar from '../ChannelAvatar/index';

export default function ChatHeader(props) {
  const {
    currentGroupChannel,
    currentUser,
    title,
    subTitle,
    isActive,
    isMuted,
    onActionClick,
    theme,
  } = props;
  const { userId } = currentUser;
  const { stringSet } = useContext(LocalizationContext);
  return (
    <div className="sendbird-chat-header">
      <div className="sendbird-chat-header__left">
        <ChannelAvatar
          theme={theme}
          channel={currentGroupChannel}
          userId={userId}
          height={32}
          width={32}
        />
        <Label
          className="sendbird-chat-header__title"
          type={LabelTypography.H_2}
          color={LabelColors.ONBACKGROUND_1}
        >
          {title || utils.getChannelTitle(currentGroupChannel, userId, stringSet)}
        </Label>
        {
          (typeof isActive === 'string' && isActive === 'true') || (typeof isActive === 'boolean' && isActive)
            ? <div className="sendbird-chat-header__active" />
            : null
        }
        <Label
          className="sendbird-chat-header__subtitle"
          type={LabelTypography.BODY_1}
          color={LabelColors.ONBACKGROUND_2}
        >
          {
            subTitle
            || <AutoRefresh repeatFunc={() => utils.getOthersLastSeenAt(currentGroupChannel)} />
          }
        </Label>
      </div>
      <div className="sendbird-chat-header__right">
        {
          (typeof isMuted === 'string' && isMuted === 'true') || (typeof isMuted === 'boolean' && isMuted)
            ? <Icon className="sendbird-chat-header__mute" type={IconTypes.MUTE} width="24px" height="24px" />
            : null
        }
        <IconButton
          className="sendbird-chat-header__info"
          width="32px"
          height="32px"
          onClick={onActionClick}
        >
          <Icon type={IconTypes.INFO} fillColor={IconColors.PRIMARY} width="24px" height="24px" />
        </IconButton>
      </div>
    </div>
  );
}

ChatHeader.propTypes = {
  currentGroupChannel: PropTypes.shape({
    members: PropTypes.arrayOf(PropTypes.shape({})),
    coverUrl: PropTypes.string,
  }),
  theme: PropTypes.string,
  currentUser: PropTypes.shape({
    userId: PropTypes.string,
  }),
  title: PropTypes.string,
  subTitle: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  isActive: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  isMuted: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  onActionClick: PropTypes.func,
};

ChatHeader.defaultProps = {
  currentGroupChannel: {},
  title: '',
  theme: 'light',
  subTitle: '',
  isActive: false,
  isMuted: false,
  currentUser: {},
  onActionClick: () => { },
};
