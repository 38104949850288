import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import Label, { LabelTypography, LabelColors } from '../Label';
import { Type, Size } from './type';
import { changeTypeToClassName, changeSizeToClassName } from './utils';

export default function Button({
  type,
  size,
  children,
  disabled,
  onClick,
  className,
}) {
  const injectingClassNames = [
    'sendbird-button',
    (disabled ? 'sendbird-button__disabled' : ''),
    changeTypeToClassName(type),
    changeSizeToClassName(size),
    ...((Array.isArray(className)) ? className : [className]),
  ].join(' ');

  return (
    <button type="button" className={injectingClassNames} onClick={onClick} disabled={disabled}>
      <Label
        className="sendbird-button__text"
        type={LabelTypography.BUTTON_1}
        color={LabelColors.ONCONTENT_1}
      >
        {children}
      </Label>
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(Type)),
  size: PropTypes.oneOf(Object.keys(Size)),
  onClick: PropTypes.func,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

Button.defaultProps = {
  children: 'Button',
  type: Type.PRIMARY,
  disabled: false,
  size: Size.BIG,
  onClick: () => { },
  className: '',
};
