import './index.scss';

import React, { ReactElement, useState } from 'react';

import AccordionGroup_ from './AccordionGroup';
import { Consumer } from './context';

import Icon, {
  IconTypes,
} from '../Icon';

interface Props {
  id: string;
  className?: string;
  renderTitle(): ReactElement;
  renderContent(): ReactElement;
  renderFooter?(): ReactElement;
}

export default function Accordion({
  id,
  renderTitle,
  renderContent,
  renderFooter,
  className,
}: Props): ReactElement {
  const [showAccordion, setShowAccordion] = useState(false);
  return (
    <Consumer>
      {
        (value) => {
          const { opened, setOpened } = value;
          if (id === opened) {
            setShowAccordion(true);
          } else {
            setShowAccordion(false);
          }
          const handleClick = () => {
            if (showAccordion) {
              setOpened('');
            } else {
              setOpened(id);
            }
          }
          return (
            <>
              <div
                id={id}
                role="switch"
                aria-checked={false}
                tabIndex={0}
                onKeyDown={handleClick}
                className={`sendbird-accordion__panel-heder ${className}`}
                onClick={handleClick}
              >
                { renderTitle() }
                <Icon
                  type={IconTypes.SHEVRON}
                  className={[
                    'sendbird-accordion__panel-icon-right',
                    'sendbird-accordion__panel-icon--chevron',
                    (showAccordion ? 'sendbird-accordion__panel-icon--open' : ''),
                  ].join(' ')}
                  height="24px"
                  width="24px"
                />
              </div>
              {
                showAccordion && (
                  <div className="sendbird-accordion">
                    <div className="sendbird-accordion__list">
                      { renderContent() }
                    </div>
                    {
                      renderFooter && (
                        <div className="sendbird-accordion__footer">
                          { renderFooter() }
                        </div>
                      )
                    }
                  </div>
                )
              }
            </>);
        }
      }
    </Consumer>
  )
}

export const AccordionGroup = AccordionGroup_;
