import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

import Label, { LabelTypography, LabelColors } from '../Label';

import MenuItems_ from './items/MenuItems';
import EmojiListItems_ from './items/EmojiListItems';

const ENTER = 13;

export const MenuItems = MenuItems_;
export const EmojiListItems = EmojiListItems_;

export const MenuItem = ({ children, onClick, className }) => {
  const injectingClassName = (Array.isArray(className)) ? className : [className];
  return (
    <li
      tabIndex={0}
      className={[
        ...injectingClassName,
        'sendbird-dropdown__menu-item',
      ].join(' ')}
      onClick={onClick}
      onKeyPress={(e) => {
        if (e.keyCode === ENTER) {
          onClick(e);
        }
      }}
      role="menuitem"
    >
      <Label
        type={LabelTypography.SUBTITLE_2}
        color={LabelColors.ONBACKGROUND_1}
        className="sendbird-dropdown__menu-item__text"
      >
        {children}
      </Label>
    </li>
  );
};

MenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
};

MenuItem.defaultProps = {
  className: '',
};

export const MenuRoot = () => (
  <div id="sendbird-dropdown-portal" />
);

export const EmojiReactionListRoot = () => (
  <div id="sendbird-emoji-list-portal" />
);

export default function ContextMenu({ menuTrigger, menuItems }) {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="sendbird-context-menu" style={{ display: 'inline' }}>
      {menuTrigger(() => setShowMenu(!showMenu))}
      {showMenu && menuItems(() => setShowMenu(false))}
    </div>
  );
}

ContextMenu.propTypes = {
  menuTrigger: PropTypes.func.isRequired,
  menuItems: PropTypes.func.isRequired,
};
