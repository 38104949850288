import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

import ChannelAvatar from '../ChannelAvatar/index';
import Badge from '../Badge';
import Icon, { IconTypes } from '../Icon';
import Label, { LabelTypography, LabelColors } from '../Label';
import { LocalizationContext } from '../../lib/LocalizationContext';

import * as utils from './utils';

export default function ChannelPreview({
  channel,
  isActive,
  ChannelAction,
  theme,
  onClick,
  tabIndex,
  currentUser,
}) {
  const {
    userId,
  } = currentUser;
  const { isBroadcast, isFrozen } = channel;
  const { stringSet } = useContext(LocalizationContext);
  return (
    <div
      role="link"
      tabIndex={tabIndex}
      onClick={onClick}
      onKeyPress={onClick}
      className={`
        sendbird-channel-preview
        ${isActive ? 'sendbird-channel-preview--active' : null}
      `}
    >
      <div
        className="sendbird-channel-preview__avatar"
      >
        <ChannelAvatar
          channel={channel}
          userId={userId}
          theme={theme}
        />
      </div>
      <div
        className="sendbird-channel-preview__content"
      >
        <div className="sendbird-channel-preview__content__upper">
          <div className="sendbird-channel-preview__content__upper__header">
            {
              isBroadcast
                && (
                  <div className="sendbird-channel-preview__broadcast-icon">
                    <Icon
                      type={
                        theme === 'dark'
                          ? IconTypes.BROADCAST_DARK
                          : IconTypes.BROADCAST_LIGHT
                      }
                      height={12}
                      width={12}
                    />
                  </div>
                )
            }
            <Label
              className="sendbird-channel-preview__content__upper__header__channel-name"
              type={LabelTypography.SUBTITLE_2}
              color={LabelColors.ONBACKGROUND_1}
            >
              {utils.getChannelTitle(channel, userId, stringSet)}
            </Label>
            <Label
              className="sendbird-channel-preview__content__upper__header__total-members"
              type={LabelTypography.CAPTION_2}
              color={LabelColors.ONBACKGROUND_2}
            >
              {utils.getTotalMembers(channel)}
            </Label>
            {
              isFrozen
                && (
                  <div title="Frozen" className="sendbird-channel-preview__frozen-icon">
                    <Icon
                      type={
                        theme === 'dark'
                          ? IconTypes.FROZEN_DARK
                          : IconTypes.FROZEN_LIGHT
                      }
                      height={12}
                      width={12}
                    />
                  </div>
                )
            }
          </div>
          <Label
            className="sendbird-channel-preview__content__upper__last-message-at"
            type={LabelTypography.CAPTION_3}
            color={LabelColors.ONBACKGROUND_2}
          >
            {utils.getLastMessageCreatedAt(channel)}
          </Label>
        </div>

        <div className="sendbird-channel-preview__content__lower">
          <Label
            className="sendbird-channel-preview__content__lower__last-message"
            type={LabelTypography.BODY_2}
            color={LabelColors.ONBACKGROUND_3}
          >
            {utils.getLastMessage(channel)}
          </Label>
          <div className="sendbird-channel-preview__content__lower__unread-message-count">
            {
              utils.getChannelUnreadMessageCount(channel)
                ? <Badge count={utils.getChannelUnreadMessageCount(channel)} />
                : null
            }
          </div>
        </div>
      </div>
      <div
        className="sendbird-channel-preview__action"
      >
        {ChannelAction}
      </div>
    </div>
  );
}

ChannelPreview.propTypes = {
  isActive: PropTypes.bool,
  theme: PropTypes.string,
  channel: PropTypes.shape({
    members: PropTypes.arrayOf(PropTypes.shape({})),
    coverUrl: PropTypes.string,
    isBroadcast: PropTypes.bool,
    isFrozen: PropTypes.bool,
  }),
  ChannelAction: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  currentUser: PropTypes.shape({
    userId: PropTypes.string,
  }),
};

ChannelPreview.defaultProps = {
  channel: {},
  isActive: false,
  theme: 'light',
  onClick: () => { },
  tabIndex: 0,
  currentUser: {},
};
