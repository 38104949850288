import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import Icon, { IconTypes, IconColors } from '../Icon';
import Label, { LabelColors, LabelTypography } from '../Label';
import Loader from '../Loader';
import MessageStatusType from './type';

import { getMessageCreatedAt } from '../../utils/utils';

const MessageStatusTypes = MessageStatusType;
export { MessageStatusTypes };
export default function MessageStatus({
  message,
  status,
  className,
}) {
  const injectingClassName = (Array.isArray(className)) ? className : [className];
  const label = () => {
    switch (status) {
      case MessageStatusType.FAILED:
      case MessageStatusType.PENDING: {
        return null;
      }
      case MessageStatusType.SENT:
      case MessageStatusType.DELIVERED:
      case MessageStatusType.READ: {
        return (
          <Label
            className="sendbird-message-status__text"
            type={LabelTypography.CAPTION_3}
            color={LabelColors.ONBACKGROUND_2}
          >
            {getMessageCreatedAt(message)}
          </Label>
        );
      }
      default: return null;
    }
  };

  const icon = {
    PENDING: (
      <Loader className="sendbird-message-status__icon" width="16px" height="16px">
        <Icon type={IconTypes.SPINNER} width="16px" height="16px" />
      </Loader>
    ),
    SENT: <Icon className="sendbird-message-status__icon" width="16px" height="16px" type={IconTypes.SENT} fillColor={IconColors.SENT} />,
    DELIVERED: <Icon className="sendbird-message-status__icon" width="16px" height="16px" type={IconTypes.DELIVERED} fillColor={IconColors.SENT} />,
    READ: <Icon className="sendbird-message-status__icon" width="16px" height="16px" type={IconTypes.READ} fillColor={IconColors.READ} />,
    FAILED: <Icon className="sendbird-message-status__icon" width="16px" height="16px" type={IconTypes.ERROR} />,
  };

  return (
    <div className={[...injectingClassName, 'sendbird-message-status'].join(' ')}>
      {icon[status]}
      <br />
      {label(status)}
    </div>
  );
}

MessageStatus.propTypes = {
  message: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
  ])),
  status: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

MessageStatus.defaultProps = {
  message: null,
  status: '',
  className: '',
};
