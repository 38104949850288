import React from 'react';
import PropTypes from 'prop-types';
import Label, { LabelTypography, LabelColors } from '../Label';

import './index.scss';

const ReactionBadge = React.forwardRef((props, ref) => {
  const {
    className,
    children,
    count,
    selected,
    isAdd,
    onClick,
  } = props;

  const injectingClassName = Array.isArray(className) ? className : [className];
  if (selected && !isAdd) {
    injectingClassName.unshift('sendbird-reaction-badge--selected');
  } else if (isAdd) {
    injectingClassName.push('sendbird-reaction-badge--is-add');
  } else {
    injectingClassName.unshift('sendbird-reaction-badge');
  }

  return (
    <div
      ref={ref}
      tabIndex={0}
      role="button"
      className={injectingClassName.join(' ')}
      onClick={onClick}
      onKeyDown={onClick}
    >
      <div className="sendbird-reaction-badge__inner">
        <div className="sendbird-reaction-badge__inner__icon">
          {children}
        </div>
        <Label className={children && count ? 'sendbird-reaction-badge__inner__count' : ''} type={LabelTypography.CAPTION_3} color={LabelColors.ONBACKGROUND_1}>
          {count}
        </Label>
      </div>
    </div>
  );
});

ReactionBadge.propTypes = {
  children: PropTypes.element.isRequired,
  count: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  selected: PropTypes.bool,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  isAdd: PropTypes.bool,
  onClick: PropTypes.func,
};

ReactionBadge.defaultProps = {
  className: '',
  count: '',
  selected: false,
  isAdd: false,
  onClick: () => { },
};

export default ReactionBadge;
